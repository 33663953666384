import { reactive, computed, onMounted, watch } from "vue";
import { useApp } from '@/composables/app';
import { useStore } from 'vuex';

import { useConfirm } from 'primevue/useconfirm';
export function useAddPage({props, formData, v$, beforeSubmit, afterSubmit}) {
	const app = useApp();
	const store = useStore();
	const confirm = useConfirm();
	const state = reactive({
		id: null,
		pageReady: false,
		submitted: false,
		saving: false,
		errorMsg: ''
	});

	setFormDefaultValues();

	const apiUrl = computed(() => {
		return props.apiPath
	});

	function validateForm(){
		state.submitted = true;
		v$.value.$validate();
		if(v$.value.$invalid){
			return false;
		}
		return true;
	}

	function normalizeFormData(formValues) {
		const postData = { ...formValues }
		Object.keys(postData).forEach(function (key) {
			const fieldValue = postData[key];
			if (Array.isArray(fieldValue)) {
				postData[key] = fieldValue.toString();
			}
			else if (fieldValue instanceof Date) {
				postData[key] = fieldValue.toISOString().slice(0, 19).replace('T', ' ');
			}
			else if(fieldValue === ''){
				postData[key] = null;
			}
		});
		return postData;
	}

	function submitForm(){
		state.submitted = true;
		if(beforeSubmit !== undefined){
			if(!beforeSubmit()){ return; }
		}

		if(!validateForm()){
			app.flashMsg(props.formValidationError, props.formValidationMsg, "error");
			return;
		}

		const confirmMsg = props.msgBeforeSave;
		if(confirmMsg){
			confirm.require({
				message: confirmMsg,
				header: props.msgTitle,
				icon: 'pi pi-save',
				accept: async () => {
					saveFormData();
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		}
		else{
			saveFormData();
		}
	}

	async function saveFormData(){
		state.saving = true;
		const url = apiUrl.value;
		let payload;
		if(Array.isArray(formData)){
			payload = formData.map(form => normalizeFormData(form));
		}
		else{
			payload = normalizeFormData(formData)
		}

		const data = { url, payload }
		try{
			const response = await store.dispatch(`${props.pageName}/saveRecord`, data);
			if(afterSubmit){
				afterSubmit(response);
			}
		}
		catch(err) {
			app.showPageRequestError(err);
		}
		finally{
			state.submitted = false;
			state.saving = false;
		}
	}

	function setFormDefaultValues(){
		const storeFormData = computed (()=> store.getters[`${props.pageName}/formData`]); // from data from store
		const pageData = props.pageData; // when form default values is passed by component props
		const formDefaultValues = { ...pageData, ...storeFormData.value };
		
		if(!Array.isArray(formData)){
			Object.assign(formData, formDefaultValues); //reset form data
		}
	}

	function getErrorClass(field, index){
		let isInvalid = false;
		if(index === undefined){
			isInvalid = v$.value[field].$invalid && state.submitted;
		}
		//for multi form validation
		else if(v$.value.$each.$response.$errors[index][field].length && state.submitted){
			isInvalid = true;
		}
		return {"p-invalid": isInvalid };
	}

	function isFieldValid(field, index){
		if(index===undefined){
			return v$.value[field].$invalid && state.submitted;	
		}
		else if(v$.value.$each.$response.$errors[index][field].length && state.submitted){
			return true;
		}
		return false;
	}
	

	function getFieldError(field, index){
		let fieldErrors = null;
		if(index===undefined){
			fieldErrors = v$.value[field].$silentErrors;
			if(fieldErrors.length){
				return fieldErrors[0].$message; //return the first error
			}
		}
		else{
			fieldErrors = v$.value.$each.$response.$errors[index][field];
			if(fieldErrors.length){
				return fieldErrors[0].$message; //return the first error
			}
		}
		return null
	}
	
	watch(() => props.pageData, (current) => {
		Object.assign(formData, current);
	  },
	  { deep: true, immediate: true }
  	);

	onMounted(()=>{ 
		state.pageReady = true;
	});

	const computedProps = {
		apiUrl,
	}

	const methods = {
		submitForm,
		getErrorClass,
		getFieldError,
		isFieldValid
	}
	
	return {
		validateForm,
		setFormDefaultValues,
		formData,
		state,
		computedProps,
		methods
	}

}