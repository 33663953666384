<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section mb-3">
				<div class="container">
					<div class="grid align-items-center">
						<div v-if="!isSubPage" class="col-fixed ">
							<Button @click="$router.go(-1)" label="" className="p-button p-button-text "
								icon="pi pi-arrow-left" />
						</div>
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div class="mr-3">
										<Avatar size="large" class="" icon="pi pi-user-plus" />
									</div>
									<div>
										<div class="text-2xl text-primary font-bold">New User Account</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="md:col-9 sm:col-12 comp-grid">
						<div>
							<form ref="observer" tag="form" @submit.prevent="submitForm()"
								:class="{ 'card': !isSubPage }" class="">
								<div class="grid">
									<div class="col-12 md:col-6">
										<div class="mb-2 font-bold"> Email *</div>
										<div id="ctrl-email-holder">
											<check-duplicate v-model="formData.email"
												check-path="components_data/users_email_exist/" v-slot="checker">
												<InputText ref="ctrlemail" @blur="checker.check"
													:loading="checker.loading" v-model.trim="formData.email"
													label="Email" type="email" placeholder="Enter Email" class=" w-full"
													:class="getErrorClass('email')">
												</InputText>
												<small v-if="isFieldValid('email')" class="p-error">{{
													getFieldError('email') }}</small>
												<small v-if="!checker.loading && checker.exist" class="p-error"> Not
													available</small>
												<small v-if="checker.loading" class="text-500">Checking...</small>
											</check-duplicate>
										</div>
									</div>
									<div class="col-12 md:col-6">
										<div class="mb-2 font-bold"> Mobile Number *</div>
										<div id="ctrl-mobile_number-holder">
											<div class="p-inputgroup flex-1">
												<span class="p-inputgroup-addon">+233</span>
												<check-duplicate class="w-full" v-model="formData.mobile_number"
													check-path="components_data/users_mobile_number_exist/"
													v-slot="checker">
													<InputText ref="ctrlmobile_number"
														v-tooltip.top.focus="'024XXXXXXX'" @blur="checker.check"
														:loading="checker.loading" v-model.trim="formData.mobile_number"
														pattern="^[0-9]+$" label="Mobile Number" minLength="10"
														maxLength="10" type="text" placeholder="Enter Mobile Number"
														class=" w-full" :class="getErrorClass('mobile_number')">
													</InputText>
													<small v-if="isFieldValid('mobile_number')" class="p-error">{{
														getFieldError('mobile_number') }}</small>
													<small v-if="!checker.loading && checker.exist" class="p-error"> Not
														available</small>
													<small v-if="checker.loading" class="text-500">Checking...</small>
												</check-duplicate>
											</div>

										</div>
									</div>
									<div class="col-12 md:col-6">
										<div class="mb-2 font-bold">User Type *</div>
										<div id="ctrl-user_type-holder">
											<Dropdown class="w-full" :class="getErrorClass('user_type')"
												optionLabel="label" optionValue="value" ref="ctrluser_type"
												v-model="formData.user_type" :options="app.menus.userTypeItems"
												label="User Type" placeholder="Select a value ...">
											</Dropdown>
											<small v-if="isFieldValid('user_type')" class="p-error">{{
												getFieldError('user_type') }}</small>
										</div>
									</div>
									<div class="col-12 md:col-6">
										<div class="mb-2 font-bold"> Account Status * </div>
										<div id="ctrl-account_status-holder">
											<Dropdown class="w-full" :class="getErrorClass('account_status')"
												optionLabel="label" optionValue="value" ref="ctrlaccount_status"
												v-model="formData.account_status"
												:options="app.menus.accountStatusItems" label="Account Status"
												placeholder="Select ...">
											</Dropdown>
											<small v-if="isFieldValid('account_status')" class="p-error">{{
												getFieldError('account_status') }}</small>
										</div>
									</div>
									<div class="col-12">
										<div class="mb-2 font-bold"> Photo * </div>
										<div id="ctrl-photo-holder">
											<div class="mb-3">
												<Uploader :class="getErrorClass('photo')"
													upload-path="fileuploader/upload/photo" v-model="formData.photo"
													:fileLimit="1" :maxFileSize="3000000" accept=".jpg,.png,.gif,.jpeg"
													:multiple="false" label="Choose files or drop files here" />
											</div>
											<small v-if="isFieldValid('photo')" class="p-error">{{
												getFieldError('photo') }}</small>
										</div>
									</div>
								</div>
								<div v-if="showSubmitButton" class="text-center my-3">
									<Button class="p-button-primary" type="submit" label="Submit" icon="pi pi-send"
										:loading="saving" />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
	import { computed, reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, email, sameAs, minLength, maxLength, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useAddPage } from '@/composables/addpage.js';
	const props = defineProps({
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'usersadd',
		},
		apiPath: {
			type: String,
			default: 'users/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Submit",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: 'Create Record',
		},
		msgAfterSave: {
			type: String,
			default: "Record added successfully",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => { }
		},
	});
	const app = useApp();
	const formDefaultValues = {
		email: "",
		mobile_number: "",
		photo: "",
		account_status: "Active",
		user_type: "",
	};
	const formData = reactive({ ...formDefaultValues });
	//form validation rules
	const rules = computed(() => {
		return {
			email: { required, email },
			mobile_number: { required, minLength: minLength(10), maxLength: maxLength(10) },
			photo: { required },
			account_status: { required },
			user_type: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); // form validation
	const page = useAddPage({ props, formData, v$, beforeSubmit, afterSubmit });
	// perform custom validation before submit form
	// set custom form fields
	// return false if validation fails
	function beforeSubmit() {
		return true;
	}
	// after form submited to api
	// reset form data.
	// redirect to another page
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		page.setFormDefaultValues(); //reset form data
		if (app.isDialogOpen()) {
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if (props.redirect) {
			app.navigateTo(`/users`);
		}
	}
	const { submitted, saving, pageReady } = toRefs(page.state);
	const { submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
	onMounted(() => {
		const pageTitle = "Add New User Account";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>